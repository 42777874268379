<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <!--CIcon :icon="logo" height="48" alt="Logo" /-->
      </CHeaderBrand>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <!--CNavItem>
          <CNavLink href="/dashboard"> Dashboard </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">Users</CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">Settings</CNavLink>
        </CNavItem-->
      </CHeaderNav>
      <CHeaderNav>
        <CNavItem>
          <!--CNavLink href="#">
            <CIcon class="mx-2" icon="cil-bell" size="lg" />
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">
            <CIcon class="mx-2" icon="cil-list" size="lg" />
          </CNavLink>
        </CNavItem>
        <CNavItem-->
          <CButton
            v-if="unsavedChanges"
            color="danger"
            style="color: white"
            class="float-start"
          >
            <CIcon icon="cilXCircle" style="margin-right: 5px"></CIcon>
            Nicht gespeicherte Änderungen
          </CButton>
          <CNavLink class="d-inline-flex" @click="logout">
            <CIcon
              class="d-inline-flex mx-2"
              icon="cil-lock-locked"
              size="lg"
            />
          </CNavLink>
        </CNavItem>
        <!--AppHeaderDropdownAccnt /-->
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <!--CContainer fluid>
      <AppBreadcrumb />
    </CContainer-->
  </CHeader>
</template>

<script>
//import AppBreadcrumb from './AppBreadcrumb'
//import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import api from '@/api'
export default {
  name: 'AppHeader',
  components: {
    //AppBreadcrumb,
    //AppHeaderDropdownAccnt,
  },
  data() {
    return {
      unsavedChanges: false,
    }
  },
  methods: {
    logout() {
      api.logout()
    },
    setUnsavedChanges(unsavedChanges) {
      this.unsavedChanges = unsavedChanges
    },
  },
}
</script>
