export default () => {
  return [
    {
      component: 'CNavItem',
      name: 'Übersicht',
      to: '/dashboard',
      icon: 'cil-chart',
    },
    {
      component: 'CNavItem',
      name: 'Terminals',
      to: '/terminals',
      icon: 'cil-view-quilt',
    },
    {
      component: 'CNavItem',
      name: 'Aktive Token',
      to: '/unregistered',
      icon: 'cil-list',
    },
    {
      component: 'CNavTitle',
      name: 'VERSIONSVERWALTUNG',
    },
    {
      component: 'CNavItem',
      name: 'Player',
      to: '/player',
      icon: 'cil-puzzle',
    },
    {
      component: 'CNavItem',
      name: 'Updater',
      to: '/updater',
      icon: 'cil-puzzle',
    },
    {
      component: 'CNavItem',
      name: 'Utilities',
      to: '/utilities',
      icon: 'cil-puzzle',
    },
    {
      component: 'CNavTitle',
      name: 'LINKS',
    },
    {
      component: 'CNavItem',
      name: 'Gitlab',
      to: 'gitlab',
      icon: 'cib-gitlab',
    },
    {
      component: 'CNavItem',
      name: 'MinIO',
      to: 'gitlab',
      icon: 'cil-storage',
    },
    {
      component: 'CNavItem',
      name: 'Test-Server',
      to: 'gitlab',
      icon: 'cil-terminal',
    },
  ]
}
