<template>
  <div>
    <router-view
      @error="handleError"
      @popupMQTT="handlePopupMQTT"
      @toast="showToast"
    />
    <ErrorModal ref="errorModal" modal-id="error-modal" />
    <CToaster placement="top-end">
      <CToast :key="index" v-for="(toast, index) in toasts">
        <CToastHeader closeButton>
          <span class="me-auto fw-bold">
            {{ toast.title }}
          </span>
        </CToastHeader>
        <CToastBody>
          {{ toast.content }}
        </CToastBody>
      </CToast>
    </CToaster>
    <MQTTPublishTopic
      ref="mqttPublisher"
      :topic="mqttTopic"
      @error="handleError"
    />
  </div>
</template>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>

<script>
import ErrorModal from '@/views/modals/ErrorModal'
import MQTTPublishTopic from '@/views/modals/MQTTPublishTopic'

export default {
  components: {
    ErrorModal,
    MQTTPublishTopic,
  },
  data() {
    return {
      toasts: [],
      mqttTopic: [],
    }
  },
  methods: {
    handleError(err) {
      this.$refs.errorModal.handle(err)
    },
    showToast(toast) {
      this.toasts.push(toast)
    },
    handlePopupMQTT(topic) {
      this.mqttTopic = topic
      this.$refs.mqttPublisher.show()
    },
  },
  mounted() {},
}
</script>
