<template>
  <CModal :visible="visible" size="lg" alignment="center" backdrop="static">
    <div class="modal-header">
      <h5 class="modal-title">MQTT-Nachricht versenden</h5>
      <CCloseButton class="float-end me-3 flex-center" @click="close" />
    </div>
    <div class="modal-body">
      <CForm style="margin: 10px">
        <CRow>
          <h6 style="padding-left: 0px !important">Payload:</h6>
          <CFormInput @input="updateTopic" :model-value="topic" />
        </CRow>
        <CRow style="margin-top: 30px">
          <h6 style="padding-left: 0px !important">Topic:</h6>
          <CodeEditor
            v-model="payload"
            width="100%"
            height="200px"
            theme="light"
            :display_language="false"
            :copy_code="false"
            :languages="[['json', 'JSON']]"
          ></CodeEditor>
        </CRow>
      </CForm>
    </div>
    <div class="modal-footer">
      <CButton
        class="float-end me-3 flex-center"
        color="danger"
        style="color: #ffffff"
        @click="publish"
      >
        Senden
      </CButton>
      <CButton
        class="float-end me-3 flex-center"
        color="secondary"
        style="color: #ffffff"
        @click="close"
      >
        Abbrechen
      </CButton>
    </div>
  </CModal>
</template>

<script>
import { CModal, CRow, CForm, CFormInput, CButton } from '@coreui/vue'
import CodeEditor from 'simple-code-editor'
import api from '@/api'
export default {
  name: 'MQTTPublishTopic',
  components: { CButton, CModal, CFormInput, CForm, CRow, CodeEditor },
  props: ['topic'],
  data() {
    return {
      topicValue: undefined,
      visible: false,
      payload:
        '{\n  "origin": "terminal",\n  "type": "control",\n  "data": "sys-my-command"\n}',
    }
  },
  methods: {
    show() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
    publish() {
      this.visible = false
      try {
        api.publishMQTT(this.topicValue, JSON.parse(this.payload))
      } catch (err) {
        this.$emit('error', {
          title: 'Fehler',
          content: err.message,
          button_text: 'Ok',
        })
      }
    },
    updateTopic(e) {
      this.topicValue = e.target.value
    },
  },
  mounted() {
    this.topicValue = this.$props.topic
  },
}
</script>
