import store from '../store'
import router from '../router'
import axios from 'axios'

async function login() {
  return new Promise((resolve, reject) => {
    const username = store.getters.getUsername
    const password = store.getters.getPassword
    const protocol = process.env.VUE_APP_IT_PROTOCOL
    const hostname = process.env.VUE_APP_IT_HOSTNAME
    const port = process.env.VUE_APP_IT_PORT

    if (username && password) {
      axios({
        method: 'GET',
        url: `${protocol}://${hostname}:${port}/terminals`,
        auth: {
          username: username,
          password: password,
        },
      })
        .then(resolve)
        .catch(reject)
    } else {
      logout()
    }
  })
}

async function sendRequest(endpoint, method, body) {
  return new Promise((resolve, reject) => {
    const username = store.getters.getUsername
    const password = store.getters.getPassword
    const protocol = process.env.VUE_APP_IT_PROTOCOL
    const hostname = process.env.VUE_APP_IT_HOSTNAME
    const port = process.env.VUE_APP_IT_PORT

    if (username && password) {
      axios({
        method: method,
        url: `${protocol}://${hostname}:${port}/${endpoint}`,
        data: body,
        auth: {
          username: username,
          password: password,
        },
      })
        .then((response) => {
          console.log(response.status)
          if (response.status === 403) logout()
          resolve(response['data'])
        })
        .catch((err) => {
          err = JSON.parse(JSON.stringify(err))
          reject(err)
          if (err.status === 403) logout()
        })
    } else {
      logout()
    }
  })
}

async function getBlob(endpoint, method, body) {
  return new Promise((resolve, reject) => {
    const username = store.getters.getUsername
    const password = store.getters.getPassword
    const protocol = process.env.VUE_APP_IT_PROTOCOL
    const hostname = process.env.VUE_APP_IT_HOSTNAME
    const port = process.env.VUE_APP_IT_PORT

    if (username && password) {
      axios({
        method: method,
        url: `${protocol}://${hostname}:${port}/${endpoint}`,
        data: body,
        auth: {
          username: username,
          password: password,
        },
        responseType: 'arraybuffer',
      })
        .then((response) => {
          resolve(response['data'])
        })
        .catch(reject)
    }
  })
}

async function getTerminals() {
  return new Promise((resolve, reject) => {
    sendRequest('terminals', 'GET').then(resolve).catch(reject)
  })
}

async function getTerminal(playerId) {
  return new Promise((resolve, reject) => {
    sendRequest(`terminal/${playerId}`, 'GET').then(resolve).catch(reject)
  })
}

async function getActivePlaylist(playerId) {
  return new Promise((resolve, reject) => {
    sendRequest(`playlist/${playerId}`, 'GET').then(resolve).catch(reject)
  })
}

async function getActiveTimeplan(playerId) {
  return new Promise((resolve, reject) => {
    sendRequest(`timeplan/${playerId}`, 'GET').then(resolve).catch(reject)
  })
}

async function getScreenshot(playerId) {
  return new Promise((resolve, reject) => {
    getBlob(`screenshot/${playerId}`, 'GET').then(resolve).catch(reject)
  })
}

async function getLogs(playerId) {
  return new Promise((resolve, reject) => {
    sendRequest(`logs/${playerId}`, 'GET').then(resolve).catch(reject)
  })
}

async function getSystemInfo(playerId) {
  return new Promise((resolve, reject) => {
    sendRequest(`system-info/${playerId}`, 'GET').then(resolve).catch(reject)
  })
}

async function getTokens() {
  return new Promise((resolve, reject) => {
    sendRequest(`tokens`, 'GET').then(resolve).catch(reject)
  })
}

async function deleteToken(token) {
  return new Promise((resolve, reject) => {
    sendRequest(`token/${token}`, 'delete').then(resolve).catch(reject)
  })
}

async function deleteTerminal(playerId) {
  return new Promise((resolve, reject) => {
    sendRequest(`terminal/${playerId}`, 'delete').then(resolve).catch(reject)
  })
}

async function publishMQTT(topic, command) {
  return new Promise((resolve, reject) => {
    const body = {
      topic: topic,
      command: command,
    }
    sendRequest(`mqtt-publish`, 'post', body).then(resolve).catch(reject)
  })
}

async function logout() {
  store.commit('updateUsername', undefined)
  store.commit('updatePassword', undefined)
  await router.push('/login')
}

export default {
  getTerminals,
  getTerminal,
  getActivePlaylist,
  getActiveTimeplan,
  getScreenshot,
  getLogs,
  getSystemInfo,
  getTokens,
  deleteToken,
  publishMQTT,
  deleteTerminal,
  login,
  logout,
}
